import React from 'react';
import { IContent } from 'vev';
import { useWidget, ModelProvider, useModel } from '../core/hooks';
import ErrorBounds from './error-bounds';
import { Addons } from './addon';

interface WidgetNodeProps {
  /** Model key */
  id: string;
  // model: IContent;
  container?: HTMLDivElement;
  children?: React.ReactNode;
  // hostRef: React.RefObject<HTMLDivElement>;
  className?: string;
  fixed?: boolean;
  section?: boolean;
}

interface NodeChildrenProps {
  ids?: string[];
  section?: boolean;
}

function WidgetNode({ id, section, fixed }: WidgetNodeProps) {
  const model = useModel(id) || ({ key: id } as IContent);
  const hostRef = React.useRef<HTMLDivElement>(null);
  const Widget = useWidget(model.type as string);
  if (!model || !model.type) return null;

  let cl = '__' + model.type;
  if (section) cl += ' __s';
  if (fixed) cl += ' __f';
  // Check if widget has added static attribute
  if (model.html || (Widget && (Widget as any).static)) cl += ' __pre';

  const contentProps: React.HTMLAttributes<HTMLDivElement> = {
    id: model.key + 'c',
    className: model.cl
  };
  if (model.html) contentProps.dangerouslySetInnerHTML = { __html: '' };
  const content = React.createElement(
    'w',
    contentProps,
    model.html || !Widget ? undefined : <Widget {...model.content} hostRef={hostRef} />
  );

  // Need to use create element to get rid of error of not supported jsx element
  return React.createElement(
    'vev',
    { id: model.key, className: cl, style: model.style, ref: hostRef },
    <ErrorBounds>
      <Addons addons={model.actions} hostRef={hostRef}>
        <ModelProvider value={model}>
          {model.pin ? <div className="__pc">{content}</div> : content}
        </ModelProvider>
        <WidgetChildren ids={model.children} section={section} />
      </Addons>
    </ErrorBounds>
  );
}

function WidgetChildren({ ids, section }: NodeChildrenProps) {
  if (!ids || !ids.length) return null;
  return (
    <div className={'__c' + (section ? ' __sc' : '')}>
      {ids.map(id => (
        <WidgetNode key={id} id={id} />
      ))}
    </div>
  );
}

export default WidgetNode;
