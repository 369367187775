import './globals';
import './viewer.scss';
import { VevContent } from 'vev';
import React from 'react';
import { hydrate } from 'react-dom';
import { Router, importAllWidgets, View, App, store, hydratePreRenderedModels } from './core';
import polyfills from './core/polyfills';

export * from './core';
export function render(content: VevContent) {
  store('content', content);
  Router.init(true);
  Promise.all([importAllWidgets(), polyfills()]).then(() => {
    View.enable();
    const root = document.getElementById('__vev');
    hydratePreRenderedModels(root);
    hydrate(<App pageKey={Router.currentPage} />, root);
  });
}
